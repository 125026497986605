.game-controls {
  display: block;
  padding: 15px;
  /*background-color: rgba(255, 255, 255, 0.5);*/
  /* position: absolute;
  left: 0;
  right: 0;
  bottom: 0; */
  color: white;
}

.game-controls .controls-top {
  overflow: hidden;
}

.game-controls .controls-left {
  width: 33.33%;
  float: left;
  font-size: 28px;
  padding-top: 30px;
}

.game-controls .controls-bottom p span,
.game-controls .controls-left p span {
  font-weight: bold;
}

.game-controls .controls-center {
  width: 33.33%;
  float: left;
  text-align: center;
  font-size: 46px;
  line-height: 50px;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.57);
}

.game-controls .controls-center .winLabel,
.game-controls .controls-center .winValue {
	padding: 0px 15px;
}

.game-controls .controls-center .winLabel {
	text-transform: uppercase;
	font-weight: bold;
}

.game-controls .controls-center .winValue {
	font-weight: bold;
}

.game-controls .controls-right {
  width: 33.33%;
  float: right;
  text-align: right;
  padding-top: 20px;
}

.game-controls .controls-bottom {
  text-align: center;
  font-size: 40px;
  line-height: 50px;
}

.game-controls .controls-bottom p {
  margin: 0;
  padding: 0;
}

.game-controls .controls-left p {
  margin: 0px 0px 10px 0px;
  padding: 0;
}

.game-controls .button {
  width: 120px;
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  text-decoration: none;
  vertical-align: middle;
}

.game-controls .button.plus {
  background-image: url("https://www.puzzleslots.com/new/images/plus.png");
}

.game-controls .button.minus {
  background-image: url("https://www.puzzleslots.com/new/images/minus.png");
}

.game-controls .button.shuffle {
  background-image: url("https://www.puzzleslots.com/new/images/shuffle.png");
  width: 160px;
  height: 160px;
}

.game-controls .button.shuffle.disabled {
  filter: grayscale(100%);
}

.game-controls .button.sound {
	width: 100px;
	height: 100px;
	display: inline-block;
	background-image: url('https://www.puzzleslots.com/new/images/sound.png');
	background-size: 100px 100px;
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: 50%;
	text-decoration: none;
	vertical-align: middle;
	margin: 0px 5px;
}

.game-controls .button.sound.disabled {
	filter: grayscale(1);
}

.toggle-magnifier {
	width: 100px;
	height: 100px;
	display: inline-block;
	background-image: url('https://www.puzzleslots.com/new/images/magnifier.png');
	background-size: 100px 100px;
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: 50%;
	text-decoration: none;
	vertical-align: middle;
	margin: 0px 5px;
}