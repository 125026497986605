.multiplier-view {
	display: block;
	text-align: center;
	background: rgb(45,233,38);
	background: linear-gradient(45deg, rgba(45,233,38,1) 0%, rgb(14, 93, 12) 44%, rgba(57,167,57,1) 100%);
	color: white;
	bottom: 0px;
	right: 0px;
	padding: 10px;
	border-radius: 50%;
	font-size: 75px;
	line-height: 120px;
	font-weight: 500;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: 120px;
	height: 120px;
}

.multiplier-wrapper {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%) !important;
	width: 120px;
	height: 120px;
}