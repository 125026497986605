.spinner {
	width: 600px;
	height: 140px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	background-color: rgb(252, 186, 3);
	overflow: hidden;
	top: -1000px;
	transition: all 1s ease;
	box-shadow: -1px -1px 26px 8px rgba(0, 0, 0, 0.7);
	border-radius: 14px;
}

.spinner.visible {
	top: 50%;
}

.spinner > .spinner-inner {
	position: relative;
	transition: all 4s ease;
}

.spinner > .spinner-inner > div {
	display: block;
	text-align: center;
	font-size: 45px;
	padding: 40px;
}

.spinner > .spinner-inner > div.free-spin {
	background-color: rgb(52, 176, 207);
}

.spinner > .spinner-inner > div.near-to-perfect {
	background-color: rgb(52, 207, 137);
}

.spinner > .spinner-inner > div.single-tile-shuffle {
	background-color: rgb(153, 207, 52);
}

.spinner > .spinner-inner > div.perfect-to-near {
	background-color: rgb(207, 179, 52);
}

.spinner > .spinner-inner > div.swap-near-tiles {
	background-color: rgb(176, 52, 207);
}

.spinner > .spinner-inner > div.all-near-to-perfect {
	background-color: rgb(224, 115, 31);
}

.spinner > .spinner-inner > div.no-bonus {
	background-color: rgb(207, 52, 67);
}
