.tile {
  aspect-ratio: 1 / 1;
  text-align: center;
  line-height: 100%;
  border-radius: 15px;
  margin: 2px;
  background: radial-gradient(circle, rgb(121, 168, 10) 0%, rgb(72, 184, 44) 53%, rgb(46, 128, 14)90%);
  position: relative;
}

.tile .inner {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 5px;
  top: 5px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.tile .inner span {
  vertical-align: middle;
  display: inline-block;
}

.tile .inner .match-text {
	position: absolute;
	padding: 5px;
	left: 0;
	top: 0;
	right: 0;
	color: black;
	text-align: center;
	font-size: 14px;
	display: none;
}

.tile .inner .multiplier {
  display: block;
  width: 20%;
  height: 20%;
  text-align: center;
  background: rgba(37, 214, 13, 0.76);
  color: white;
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 10px;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 500;
}
