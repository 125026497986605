.template {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.templateWrapper {
  position: absolute;
  width: 50%;
  height: 50%;
  box-sizing: border-box;
  border-radius: 20px;
  -webkit-box-shadow: 1px -1px 25px 7px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 1px -1px 25px 7px rgba(0, 0, 0, 0.43);
  box-shadow: 1px -1px 25px 7px rgba(0, 0, 0, 0.8);
  background-color: rgba(79, 33, 25, 0.46);
  transition: all 0.5s ease;
  border: 5px solid rgba(255, 255, 255, 0.8);
}

.templateWrapper .template .tile {
  background: radial-gradient(circle, rgb(43, 187, 223) 0%, rgb(78, 166, 207) 53%, rgb(43, 76, 223)90%);
}

.templateWrapper .template .tile.perfect {
  background: rgb(45,233,38);
  background: linear-gradient(45deg, rgba(45,233,38,1) 0%, rgb(125, 255, 18) 44%, rgb(7, 71, 7) 100%);
  transition: all .6s ease;
}

.templateWrapper .template .tile.near {
  background: rgb(226,233,38);
  background: linear-gradient(45deg, rgba(226,233,38,1) 0%, rgba(214,255,18,1) 44%, rgb(102, 105, 8) 100%);
  transition: all .6s ease;
}