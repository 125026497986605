.dashboard {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
}

.dashboard .tile {
	background: rgba(0,0,0,0.7) !important;
}

.dashboard .tile {
	position: relative;
}

.dashboard .tile::before {
	content: "";
	position: absolute;
	display: block;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgb(255,128,31);
	background: radial-gradient(circle, rgba(255,128,31,0) 0%, rgba(255, 255, 255, 0.4) 25%, rgba(255,128,31,0) 100%);
}

.dashboardWrapper {
  width: 80vh;
  height: 80vh;
  margin: 0px auto 10px;
  position: relative;
  box-shadow: -1px -1px 26px 8px rgba(0, 0, 0, 0.37);
  border-radius: 14px;
  visibility: hidden;
  /* background: linear-gradient(45deg, rgba(16,108,144,1) 0%, rgba(65,187,235,1) 50%, rgba(28,147,193,1) 100%); */
  transition: all 0.5s ease;
}

.dashboardWrapper.dashboardWrapperSmall {
  width: 60vh;
  height: 60vh;
}

.dashboardWrapper.visible {
  visibility: visible;
}
